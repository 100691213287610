import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

const CatalogItemPage = () => (
	<Layout>
		<Seo title="The Christmas Song" />

		<h2>The Christmas Song</h2>

		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST08 christmas song front.jpg"
			alt="Jesse Rivest - The Christmas Song - cover art"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3>Prerelease note</h3>
		<p>
			To be released on December 16, 2021.
		</p>

		<h3>Words from Jesse</h3>
		<p>
			My second recorded and released cover song!
			For many, many years I've felt admiration for the late Nat King Cole's amazing singing and voice.
			I even went a while using <span className="italic">Unforgettable</span> as a warm-up song when practicing singing;
			more recently I've been using <span className="italic">The Christmas Song</span>.
			Why not record and release it, now that I've figured out how to release cover songs?
			I must of course nod to one of the songwriters—another stunning singer—the late Mel Tormé.
			Love him—I especially like his version of <span className="italic">Moonlight Cocktail</span>.
			Well, here we have me making my own soft crooning effort, as well as having a bit of fun with the harmony of the song.
			I hope you find it interesting... or even like it.
		</p>

		<h3>Track listing</h3>
		<ol>
			<li>
				The Christmas Song (3:52)<br />
				T0700249611 - © Morris Edwin H & Co Inc (ASCAP), Sony/ATV Tunes LLC (ASCAP) 1945<br />
				CA-6P8-21-00005 - © Jesse Rivest 2021 (PPL)
			</li>
		</ol>
		<p>
			This recording: catalog number JRIVEST08, © Jesse Rivest 2021, All Rights Reserved<br />
			UPC/EAN: 0198001574962<br />
			Released: December 16, 2021
		</p>

		<h3>Credits</h3>
		<p>
			Produced, recorded, mixed, and mastered by Jesse Rivest at his Little Bakery Studio in Brasilia, DF, Brazil.<br />
			Song sung by Jesse Rivest.<br />
			Acoustic and electric guitar played by Jesse Rivest.<br />
			Coral harmonies by Jesse Rivest.<br />
			Homemade Christmas card pictured by Judy Tailer.<br />
			Album cover photograph by Jesse Rivest.<br />
			Album cover layout by Jesse Rivest.<br />
		</p>

		<h3>Special Thanks</h3>
		<p>
			Thanks to Jane Lino for being supportive, always.
		</p>

		<h3>Extras</h3>

		<h4>Back cover</h4>
		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST08 christmas song back.png"
			alt="Jesse Rivest - The Christmas Song - back cover"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h4>Harmony</h4>
		<div className="p">
			<pre className="monoblock">
2/4 in C. 60 bpm.  INTRO A A B A B A CODA
<br />
<br />INTRO
<br />|| IV7sus2   | I/5         | VIm7       |    V7/3             |
<br /> | I(9)      | %           | I69        | %                  ||
<br />
<br />A
<br />|| I         | IV7M/9      | VIm7       | IVm7sus2/VI   V7-&gt;  |
<br /> | VIm       | subV7-&gt;V7-&gt; | VIm7       | subV7/V             |
<br /> | I7M(9)    | IIm7        | IIIm7      | 1. V7#5/7  2. bIIIo |
<br />1| bVI/6     | bVI6   IV6  | V7         | V7                  |
<br />2| I69 IIm7  | VIm7   V7   | I          | I7M               :||
<br />
<br />B §
<br />(in F)
<br />|| IV6       | V9          | IV6        | V69                 |
<br /> | IIm       | V7          | I7M        | I6                  |
<br />(in Eb)
<br /> | IV6       | V7          | I7M        | bVII7M              |
<br />(in C)
<br /> | IIm(11)/V | V7/V        | V7         | V7#5               ||
<br />
<br />A
<br />|| I         | IV7M/9      | VIm7       | IVm7sus2/VI  V7/V-&gt; |
<br /> | VIm       | subV7-&gt;V7-&gt; | VIm7       | subV7/V             |
<br /> | I7M(9)    | IIm7        | IIIm7      | bIIIo               |
<br /> | I69 IIm7  | VIm7   V7   | I          | I7M                ||
<br />                               coda        d.s al coda
<br />
<br />CODA
<br />|| subV7/V         | I7M(9)      | V7         | IV7sus2       |
<br /> | V74(13) V7(13)  | I7M/5     |||
 			</pre>
		</div>

		<p>
			<Link to="../">Go back to Music Catalog</Link>
		</p>
	</Layout>
);

export default CatalogItemPage;
